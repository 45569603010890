<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-header">
            <vb-headers-heading-2
              :data="{
                title: 'Manage your Forecast',
                description:
                  'Watch and add any scheduled payments or installments you have - including real estate installments, car loans, school fees, membership fees and more',
              }"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <div class="card card-top card-top-primary">
          <div class="card-body"><vb-widgets-general-2 /></div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card card-top card-top-primary">
          <div class="card-body"><vb-widgets-general-2 /></div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card card-top card-top-primary">
          <div class="card-body"><vb-widgets-general-2v2 /></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-top card-top-primary">
          <div class="card-header">
            <vb-headers-card-header :data="{ title: 'Payment Orders on Installments' }" />
          </div>
          <div class="card-body"><vb-widgets-tables-1 /></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-top card-top-primary">
          <div class="card-header py-0">
            <vb-headers-card-header-2 :data="{ title: 'Track installment payments' }" />
          </div>
          <div class="card-body"><vb-widgets-tables-6 /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VbHeadersHeading2 from '@/@vb/widgets/Headers/Heading2'
import VbWidgetsGeneral2 from '@/@vb/widgets/WidgetsGeneral/2'
import VbWidgetsGeneral2v2 from '@/@vb/widgets/WidgetsGeneral/2v2'
import VbHeadersCardHeader from '@/@vb/widgets/Headers/CardHeader'
import VbWidgetsTables1 from '@/@vb/widgets/WidgetsTables/1'
import VbHeadersCardHeader2 from '@/@vb/widgets/Headers/CardHeader2'
import VbWidgetsTables6 from '@/@vb/widgets/WidgetsTables/6'

export default {
  name: 'VbForecasting',
  components: {
    VbHeadersHeading2,
    VbWidgetsGeneral2,
    VbWidgetsGeneral2v2,
    VbHeadersCardHeader,
    VbWidgetsTables1,
    VbHeadersCardHeader2,
    VbWidgetsTables6,
  },
}
</script>
